<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.show_conclusions") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                :loading="waitingPrint"
                @click="print('conclusion/print', model.id)"
                >{{ $t("message.print") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="modal-body">
          <el-row>
            <el-col class="ml-5" :span="12">
              {{ $t("message.doctor_name") }} :
              <b>
                {{
                  model.doctor
                    ? model.doctor.name +
                      " " +
                      (model.doctor.surname ? model.doctor.surname : "") +
                      " " +
                      (model.doctor.patronymic ? model.doctor.patronymic : "")
                    : ""
                }}
              </b>
            </el-col>
            <el-col :span="8">
              {{ $t("message.date") }} :
              <b>
                {{ model.created_at ? model.created_at : "-" }}
              </b>
            </el-col>
            <el-divider></el-divider>
            <el-col :span="24">
              <el-card class="ml-5" style="width: 826px" shadow="always">
                <div class="content-table" v-html="model.content"></div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import print from "@/utils/mixins/print";
export default {
  mixins: [form, print],
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  data() {
    return {
      waitingPrint: false,
    };
  },
  watch: {
    reloadModel: {
      handler: function () {
        if (this.reloadModel) {
          this.loadModel();
        }
      },
      deep: true,
    },
  },
  created() {},
  async mounted() {},
  computed: {
    ...mapGetters({
      rules: "conclusions/rules",
      model: "conclusions/model",
      columns: "conclusions/columns",
    }),
  },
  methods: {
    ...mapActions({
      save: "conclusions/update",
    }),
    loadModel() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$emit("c-close", { drawer: "drawerUpdate" });
              this.$alert(res);
              this.$refs["form"].resetFields();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    resetForm(formName) {
      this.$emit("c-close", { drawer: "drawerUpdate" });
    },
    // print(id) {
    //     this.waitingPrint = true;
    //     let vm = this;
    //     var jqxhr = $.get(process.env.VUE_APP_URL + `conclusion/print?id=${id}`, function() {})
    //         .done(function(res) {
    //             vm.waitingPrint = false;
    //             const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
    //             WinPrint.document.write(res);
    //             WinPrint.document.close();
    //             WinPrint.focus();
    //             setTimeout(() => {
    //                 WinPrint.print();
    //                 WinPrint.close();
    //             }, 1000);
    //         })
    //         .fail(function() {
    //             alert("Не может печататься");
    //             vm.waitingPrint = false;
    //         })
    // },
  },
};
</script>
